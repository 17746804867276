import { CRUDTypeEnum } from 'src/helper/enum/CRUDTypeEnum';
import { PaginationEnum } from 'src/helper/enum/PaginationEnum';
import { getNumberPagination } from 'src/helper/pagination/PaginationHelper';
import ROOT_API from '../../../MyServices/api/URLApi';

export const TICKET_LIST = 'TICKET_LIST';
export const TICKET_SUBMIT_ADD = 'TICKET_SUBMIT_ADD';
export const TICKET_DETAIL_DATA = 'TICKET_DETAIL_DATA';
export const TICKET_DELETE = 'TICKET_DELETE';

export const TicketGetList = (data) => {
    return async dispatch => {
        const page = data.page ?? 1;
        const q = data.q;
        const vendorId = data.vendorId;

        const params = { limit: PaginationEnum.DEFAULT_PAGE, page, q };

        if (vendorId)
            params.vendor_id = vendorId;

        const getData = await ROOT_API.get('vendor_ticket/admin', { params });

        let datas = getData.data;
        let res = {};
        res['count'] = datas.meta.total_items;
        res['pagination'] = datas.pagination;
        res['dataSource'] = [];
        let no = getNumberPagination(page);

        datas.data.map((v) => {
            res['dataSource'].push(
                {
                    key: v.id,
                    no,
                    name: v.name,
                    description: v.description,
                    price: new Intl.NumberFormat('id-ID', { style: "currency", currency: "IDR", minimumFractionDigits: 0 }).format(v.price),
                    discount_price: v.discount_price === null ? 'Not set' : new Intl.NumberFormat('id-ID', { style: "currency", currency: "IDR", minimumFractionDigits: 0 }).format(v.discount_price),
                    qty: v.qty,
                }
            );
            no += 1;
            return res;
        });

        return dispatch({
            type: TICKET_LIST,
            payload: res
        });
    };
}

export const TicketAddOrEdit = data => {
    return async dispatch => {
        const payload = {
            name: data.name,
            description: data.description,
            price: data.price,
            discount_price: data.discount_price,
            qty: data.qty,
        };

        if (data.vendorId)
            payload.vendor_id = data.vendorId;

        const hit = data.type === CRUDTypeEnum.ADD ?
            await ROOT_API.post('vendor_ticket', payload) :
            await ROOT_API.patch(`vendor_ticket/${data.id}`, payload);

        return dispatch({
            type: TICKET_SUBMIT_ADD,
            payload: hit.data.data,
            status: hit.status,
        });
    };
}

export const GetDetailTicket = payload => {
    return async dispatch => {
        const data = await ROOT_API.get(`vendor_ticket/${payload.id}`);

        return dispatch({
            type: TICKET_DETAIL_DATA,
            data: data.data.data,
            status: data.status,
        });
    };
}

export const TicketDelete = payload => {
    return async dispatch => {
        const hit = await ROOT_API.delete(`vendor_ticket/${payload}`)

        return dispatch({
            type: TICKET_DELETE,
            status: hit.status
        });
    };
}
