import { VENDOR_LIST } from "./VendorAction"

const INITIAL_STATE = {
    Vendors: {
        Action: '',
        icon: 'nav-icon icon-layers',
        modul: 'Manage All Content',
        dataSource: [],
        count: 0,
    },
}

const VendorReducer = (state = { ...INITIAL_STATE }, action) => {
    switch (action.type) {
        case VENDOR_LIST:
            return ({
                ...state,
                Vendors: {
                    ...state.Vendors,
                    dataSource: action.payload.dataSource,
                    count: action.payload.count,
                    pagination: action.payload.pagination,
                }
            })
        default:
            return state;
    }
}

export default VendorReducer