import { CRUDTypeEnum } from 'src/helper/enum/CRUDTypeEnum';
import ROOT_API from '../../../MyServices/api/URLApi';
import { PaginationEnum } from '../../../helper/enum/PaginationEnum';
import Axios from 'axios';

export const VENDOR_LIST = 'VENDOR_LIST';
export const VENDOR_SUBMIT_ADD = 'VENDOR_SUBMIT_ADD';
export const VENDOR_DETAIL_DATA = 'VENDOR_DETAIL_DATA';
export const VENDOR_DELETE = 'VENDOR_DELETE';
export const CATEGORIES = 'CATEGORIES';
export const LOCATION = 'LOCATION';
export const FACILITIES = 'FACILITIES';
export const LANGUAGES = 'LANGUAGES';

export const VendorGetList = (data) => {
    return async dispatch => {
        const page = data.page ?? 1;
        const q = data.q;

        const getData = await ROOT_API.get('vendor', { params: { limit: PaginationEnum.DEFAULT_PAGE, page, q } });

        let datas = getData.data;
        let res = {};
        res['count'] = datas.meta.total_items;
        res['pagination'] = datas.pagination;
        res['dataSource'] = [];
        let no = page === 1 ? 1 : (page - 1) * PaginationEnum.DEFAULT_PAGE + 1;

        datas.data.map((v) => {
            res['dataSource'].push(
                {
                    key: v.id,
                    no,
                    name: v.name,
                    description: v.description,
                    province: v.urban_village?.sub_district.city.province.name ?? 'Not Set',
                    city: v.urban_village?.sub_district.city.name ?? 'Not Set',
                }
            );
            no += 1;
            return res;
        });

        return dispatch({
            type: VENDOR_LIST,
            payload: res
        });
    };
}

export const VendorAddOrEdit = data => {
    return async dispatch => {
        const payload = {
            name: data.name,
            description: data.description,
            qr_code: data.qr_code,
            longitude: data.longitude,
            latitude: data.latitude,
            address: data.address,
            urban_village_id: data.urban_village.value,
            categories: data.selectedCategories.map(e => e.value),
            facilities: data.selectedFacilities.map(e => e.value),
            languages: data.selectedLanguages.map(e => e.value),
        };

        if (data.isVendorPending) {
            payload.approval_type = "approved"
        }

        if (data.schedule_time_open && data.schedule_time_close && data.schedule_time_last_schedule) {
            payload.schedule = {
                time_open: data.schedule_time_open,
                time_close: data.schedule_time_close,
                time_last_schedule: data.schedule_time_last_schedule,
            };
        }

        if (data.images) {
            const presigns = await ROOT_API.post('upload/presign/list', {
                'folder': 'vendor',
                'file_names': Array.from(data.images).map(e => e.name)
            });

            payload.images = presigns.data.data.map(e => e.download_url);

            await presigns.data.data.forEach(async (e, index) => {
                await Axios.create({
                    headers: {
                        'Accept': '*/*',
                    }
                }).put(e.upload_url, data.images[index]);
            });

            if (data.id) payload.is_replace_images = true;
        }

        const hit = data.type === CRUDTypeEnum.ADD ?
            await ROOT_API.post('vendor', payload) :
            await ROOT_API.patch(`vendor/${data.id}`, payload);

        return dispatch({
            type: VENDOR_SUBMIT_ADD,
            payload: hit.data.data,
            status: hit.status,
        });
    };
}

export const GetDetailVendor = payload => {
    return async dispatch => {
        const data = await ROOT_API.get(`vendor/${payload.id}/admin`);

        return dispatch({
            type: VENDOR_DETAIL_DATA,
            data: data.data.data,
            status: data.status,
        });
    };
}

export const VendorDelete = payload => {
    return async dispatch => {
        const hit = await ROOT_API.delete(`vendor/${payload}`)

        return dispatch({
            type: VENDOR_DELETE,
            status: hit.status
        });
    };
}

export const GetCategories = payload => {
    return async dispatch => {
        const data = await ROOT_API.get('vendor_category', { params: { q: payload.q } });

        return dispatch({
            type: CATEGORIES,
            data: data.data.data,
            status: data.status,
        });
    };
}

export const GetFacilities = payload => {
    return async dispatch => {
        const data = await ROOT_API.get('vendor-facilities', { params: { q: payload.q } });

        return dispatch({
            type: FACILITIES,
            data: data.data.data,
            status: data.status,
        });
    };
}

export const GetLanguages = payload => {
    return async dispatch => {
        const data = await ROOT_API.get('languages', { params: { q: payload.q } });

        return dispatch({
            type: LANGUAGES,
            data: data.data.data,
            status: data.status,
        });
    };
}

export const GetProvince = payload => {
    return async dispatch => {
        const data = await ROOT_API.get('location/province', { params: { q: payload.q } });

        return dispatch({
            type: LOCATION,
            data: data.data.data,
            status: data.status,
        });
    };
}

export const GetCity = payload => {
    return async dispatch => {
        const data = await ROOT_API.get('location/city', { params: { q: payload.q, province_id: payload.province_id } });

        return dispatch({
            type: LOCATION,
            data: data.data.data,
            status: data.status,
        });
    };
}

export const GetSubDistrict = payload => {
    return async dispatch => {
        const data = await ROOT_API.get('location/sub_district', { params: { q: payload.q, city_id: payload.city_id } });

        return dispatch({
            type: LOCATION,
            data: data.data.data,
            status: data.status,
        });
    };
}

export const GetUrbanVillage = payload => {
    return async dispatch => {
        const data = await ROOT_API.get('location/urban_village', { params: { q: payload.q, sub_district_id: payload.sub_district_id } });

        return dispatch({
            type: LOCATION,
            data: data.data.data,
            status: data.status,
        });
    };
}