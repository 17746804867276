import axios from 'axios';
import cookie from 'react-cookies'
import { API_URL } from 'src/helper/static';

export default axios.create({
   baseURL: API_URL,
   headers: {
      'Authorization': `Bearer ${cookie.load('access_token')}` ,
      'Content-Type': 'application/json',
   }
})
