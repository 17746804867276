import { CRUDTypeEnum } from 'src/helper/enum/CRUDTypeEnum';
import ROOT_API from '../../../MyServices/api/URLApi';
import { PaginationEnum } from '../../../helper/enum/PaginationEnum';
import { getNumberPagination } from 'src/helper/pagination/PaginationHelper';
import Axios from 'axios';

export const CONTENTS_LIST = 'CONTENTS_LIST'
export const CONTENTS_SUBMIT_ADD = 'CONTENTS_SUBMIT_ADD'
export const CONTENTS_DETAIL_DATA = 'CONTENTS_DETAIL_DATA'
export const CONTENTS_DELETE = 'CONTENTS_DELETE'

export const ContentGetList = (page, vendor_id) => {
    return async dispatch => {
        const getData = await ROOT_API.get('video/admin', { params: { limit: PaginationEnum.DEFAULT_PAGE, page, vendor_id } });

        let datas = getData.data
        let res = {}
        res['count'] = datas.meta.total_items;
        res['pagination'] = datas.pagination;
        res['dataSource'] = []
        let no = getNumberPagination(page);

        datas.data.map((v) => {
            res['dataSource'].push(
                {
                    key: v.id,
                    no,
                    thumbnail: v.thumbnail_url,
                    description: v.description,
                    total_like: v.like_count,
                }
            )
            no += 1;
            return res
        })

        return dispatch({
            type: CONTENTS_LIST,
            payload: res
        })
    }
}

export const ContentAddOrEdit = data => {
    return async dispatch => {
        const payload = {
            description: data.description,
            source_title: data.source_title,
            source_url: data.source_url,
            source_category: data.source_category?.toLowerCase(),
        };

        if (data.video) {
            console.log(data);
            const base64Data = new Buffer.from(data.thumbnail_video.replace(/^data:image\/\w+;base64,/, ""), 'base64');

            const presigns = await ROOT_API.get(`upload/presign/video`, {
                params: {
                    'video_file_name': data.video.name,
                    'thumbnail_file_name': data.video.name.replace('mp4', 'jpeg')
                }
            })

            payload.url = presigns.data.data.video.download_url;
            await Axios.create({
                headers: {
                    'Accept': '*/*',
                }
            }).put(presigns.data.data.video.upload_url, data.video)

            payload.thumbnail_url = presigns.data.data.thumbnail.download_url;
            await Axios.create({
                headers: {
                    'Accept': '*/*',
                }
            }).put(presigns.data.data.thumbnail.upload_url, base64Data);

        }

        if (data.vendorId)
            payload.vendor_id = data.vendorId

        const hit = data.type == CRUDTypeEnum.ADD ?
            await ROOT_API.post('video', payload) :
            await ROOT_API.patch(`video/${data.id}`, payload);

        return dispatch({
            type: CONTENTS_SUBMIT_ADD,
            payload: hit.data.data,
            status: hit.status
        })
    }
}

export const GetDetailContent = payload => {
    return async dispatch => {
        const data = await ROOT_API.get(`video/${payload.id}`);

        return dispatch({
            type: CONTENTS_DETAIL_DATA,
            data: data.data.data,
            status: data.status
        })
    }
}

export const ContentDelete = payload => {
    return async dispatch => {
        const hit = await ROOT_API.delete(`video/${payload}`)

        return dispatch({
            type: CONTENTS_DELETE,
            status: hit.status
        })
    }
}

//task cholis buat belajar
// export const SellingAddAction = payload => {
//     return async dispatch => {
//         const PostEntry = await ROOT_API.post('transaction/penjualan', payload)
//         return dispatch({
//             type: SELLINGADD,
//             payload:PostEntry.data.meta
//         })
//     }
// }