import { combineReducers } from 'redux';
import authreducer from '../views/pages/login/authreducer'
import AllDashboardReducer from '../views/dashboard/ZDashboardReducer'
import ContentReducer from '../views/pages/content/ContentReducer'
import TransactionReducer from '../views/TransactionManagement/TransactionReducer'
import ZReportReducer from '../views/Report/ZReportReducer'
import TicketReducer from '../views/pages/vendor-ticket/TicketReducer'
import VendorReducer from '../views/pages/manage-vendor/VendorReducer'

export default combineReducers({
   authreducer,
   AllDashboardReducer,
   ContentReducer,
   TransactionReducer,
   TicketReducer,
   VendorReducer,
   ZReportReducer,
})