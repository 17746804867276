import { CONTENTS_LIST } from "./ContentAction"

const INITIAL_STATE = {
    Contents: {
        Action: '',
        icon:'nav-icon icon-layers',
        modul:'Manage All Content',
        dataSource: [],
        count: 0,
    },
}

const ContentReducer = (state = { ...INITIAL_STATE }, action) => {
    switch (action.type) {
        case CONTENTS_LIST:
            return ({
                ...state,
                Contents: {
                    ...state.Contents,
                    dataSource: action.payload.dataSource,
                    count: action.payload.count,
                    pagination: action.payload.pagination,
                }
            })
        default:
            return state;
    }
}

export default ContentReducer