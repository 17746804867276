import { TICKET_LIST } from "./TicketAction"

const INITIAL_STATE = {
    Tickets: {
        Action: '',
        icon: 'nav-icon icon-layers',
        modul: 'Manage All Content',
        dataSource: [],
        count: 0,
    },
}

const TicketReducer = (state = { ...INITIAL_STATE }, action) => {
    switch (action.type) {
        case TICKET_LIST:
            return ({
                ...state,
                Tickets: {
                    ...state.Tickets,
                    dataSource: action.payload.dataSource,
                    count: action.payload.count,
                    pagination: action.payload.pagination,
                }
            })
        default:
            return state;
    }
}

export default TicketReducer